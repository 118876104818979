<template>
  <div class="overflow-y-auto p-4">
    <h1 class="mb-2">
      {{ t("headings.document") }}
    </h1>
    <div
      class="card p-4"
      :class="{
        'flex h-1/2 items-center justify-center': waiting,
      }"
    >
      <p v-if="waiting" class="text-xl font-bold">{{ t("labels.loading") }}</p>
      <div v-else>
        <h3 class="mb-3">{{ document.name }}</h3>
        <read-only
          v-if="document.content"
          :content="JSON.parse(document.content)"
        />
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-end">
          <div
            v-if="
              document.signatories &&
              document.signatories[0].status === 'signed'
            "
            class="flex items-center gap-2"
          >
            <CheckCircleIcon class="h-6 w-6 text-green-600" />
            <p class="text-lg font-semibold">
              {{ t("labels.signed") }}
            </p>
          </div>
          <form-button
            v-else
            :command="() => sign()"
            :disabled="waiting"
            id="sign-button"
            label="sign"
            textVariant="light"
            type="submit"
            variant="indigo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ReadOnly from "../components/editor/ReadOnly.vue";
import FormButton from "../components/form/FormButton.vue";
export default {
  components: { CheckCircleIcon, FormButton, ReadOnly },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const document = computed(() => store.state.document.document);

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!document.value.id || document.value.id != route.params.documentId) {
        store.dispatch("document/getSignableDocument", {
          documentId: route.params.documentId,
          slug: route.params.slug,
        });
      }
    });

    return {
      document,
      router,
      sign: () =>
        store.dispatch("document/signDocument", {
          documentId: document.value.id,
          slug: route.params.slug,
        }),
      t,
      waiting,
    };
  },
};
</script>
